const ROUTES = {
  DASHBOARD: '',
  SUBMISSIONS: 'submissions',
  SUBMISSIONS_DETAILS: 'submissions/details',
  ACTIVITIES_AUDIT: 'audit/:id',
  INSTALLERS: 'installers',
  PROFILE_SETTINGS: 'profile-settings',
  PROJECT_MANAGEMENT: 'project-management',
  CERTIFICATE_VALUE: 'certificate-value',
  PROJECT_DETAILS: 'project-management/:project',
  AUDIT: 'audit',
};

export default ROUTES;

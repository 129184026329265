import { Component, EventEmitter, Output } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-search-text',
  standalone: true,
  imports: [InputTextModule, SharedModule],
  templateUrl: './search-text.component.html',
  styleUrl: './search-text.component.scss',
})
export class SearchFieldComponent {
  searchQuery: string = '';

  @Output() searchEvent = new EventEmitter<string>();

  search(): void {
    this.searchEvent.emit(this.searchQuery);
  }
}

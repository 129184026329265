<div class="page-container activity-container">
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
  </div>
  <div>
    <p-table [columns]="BundleCols" [value]="activitiesList" dataKey="id">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoading">
          <tr *ngFor="let _ of skeletonRows">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-expanded="expanded"
      >
        <tr>
          <td class="activity-name" (click)="submissionDetails(rowData)">
            {{ rowData.installerName }}
          </td>
          <td>
            <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
            {{ rowData.createdAt | date : "yyyy-MM-dd hh:mm:ss" }}
          </td>
          <td style="width: 10%">
            <div [ngClass]="getStatusClass(rowData.status)">
              {{ rowData.status }}
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" *ngIf="activitiesList.length === 0">
        <tr *ngIf="!isLoading">
          <td colspan="5">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

export const environment = {
  production: false,
  baseUrl: window.origin,
  msalConfig: {
    auth: {
      clientId: '931d6ab6-6e2d-4ca7-83a9-2a992b4819a7',
    },
  },
  apiConfig: {
    scopes: [
      'https://themisagg.onmicrosoft.com/24c35a47-161f-4fa2-a56a-dc91c7e79741/access_as_user',
    ],
    uri: 'https://aggregate.themis.dev.dact.cloud/api',
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'b2c_1_susi',
      resetPassword: 'b2c_1_reset',
      editProfile: 'b2c_1_edit_profile',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://themisagg.b2clogin.com/themisagg.onmicrosoft.com/B2C_1_susi',
      },
      resetPassword: {
        authority:
          'https://themisagg.b2clogin.com/themisagg.onmicrosoft.com/B2C_1_Reset',
      },
      editProfile: {
        authority:
          'https://themisagg.b2clogin.com/themisagg.onmicrosoft.com/B2C_1_ProfileEdit',
      },
    },
    authorityDomain: 'themisagg.b2clogin.com',
  },
  ABN_URL:
    'https://abr.business.gov.au/json/AbnDetails.aspx?callback=JSONP_CALLBACK&guid=f5403340-360c-483d-ae43-ea38cb140ca0&noCacheIE=1680516399638',
};

import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { TableCols } from '../../../types';
import { AUDIT_JOB_COLS } from '../../../constants';
import { ButtonComponent } from '../../../Components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { TooltipModule } from 'primeng/tooltip';
import {
  AGENT_LIST,
  ASSESSMENT_LISt,
  INSTALLATION_LIST,
} from '../../../mock-data';

@Component({
  selector: 'app-job-audit',
  standalone: true,
  templateUrl: './job-audit.component.html',
  styleUrl: './job-audit.component.scss',
  imports: [SharedModule, ButtonComponent, DropdownComponent, TooltipModule],
})
export class JobAuditComponent {
  jobData: any;
  assessmentCols: TableCols[] = AUDIT_JOB_COLS;
  AssessmentList: any[] = ASSESSMENT_LISt;
  InstallationList: any[] = INSTALLATION_LIST;
  allFields: any[] = [];
  statusCounts = { pass: 0, reject: 0, empty: 0 };
  rejectDialog: boolean = false;
  formData!: FormGroup;
  AgentList: any[] = AGENT_LIST;

  constructor(private formBuilder: FormBuilder) {
    this.initialForm();
  }

  initialForm(): void {
    this.formData = this.formBuilder.group({
      reason: ['', Validators.required],
      agent: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.jobData = history.state.rowData;
    this.allFields.push(...this.AssessmentList, ...this.InstallationList);
    this.updateStatusCounts();
  }

  updateStatusCounts(): void {
    this.statusCounts = this.allFields.reduce(
      (counts, item) => {
        if (item.status === 'pass') {
          counts.pass++;
        } else if (item.status === 'reject') {
          counts.reject++;
        } else {
          counts.empty++;
        }
        return counts;
      },
      { pass: 0, reject: 0, empty: 0 }
    );
  }

  navigateBack(): void {
    window.history.back();
  }

  onAuditStatus(status: string): void {
    if (status === 'reject') this.rejectDialog = true;
    this.updateStatusCounts();
  }

  clearStatus(rowData: any): void {
    rowData.status = '';
    this.updateStatusCounts();
  }

  onSaveAudit(): void {
    window.history.back();
  }

  onCancelAudit(): void {
    window.history.back();
  }

  onSaveReject(): void {
    this.rejectDialog = false;
    this.initialForm();
  }

  onCancelReject(): void {
    this.rejectDialog = false;
    this.initialForm();
  }
}

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { SchemeActivity } from '../../types';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private api: ApiService) {}

  async fetchAggregatorData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/aggregator/details`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAggregatorData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/aggregator`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchUserData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/aggregator/user`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createUserData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/user`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/aggregator/user`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserProfileImage(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/user/profileImage`, formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCompanyLogo(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/logo`, formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchUserProfilePic(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.getBlob(`api/aggregator/user/profileImage`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchCompanyLogo(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.getBlob(`api/aggregator/logo`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchActivitiesData(): Promise<any> {
    try {
      const data: SchemeActivity[] = await firstValueFrom(
        this.api.get(`api/aggregator/schemes-with-activities`)
      );
      const sortedData = data.sort((a, b) =>
        a.schemeAndActivity.localeCompare(b.schemeAndActivity)
      );
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async postAccreditationData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/aggregator/schemes-with-activities`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchSelectedActivities(): Promise<any> {
    try {
      const data: SchemeActivity[] = await firstValueFrom(
        this.api.get(`api/aggregator/selected-activities`)
      );
      const sortedData = data.sort((a, b) =>
        a.schemeAndActivity.localeCompare(b.schemeAndActivity)
      );
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async updateUserStatus(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/aggregator/user/set-active`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { TableCols } from '../../types';
import { ACTIVITIES_BUNDLE_COLS, BUNDLE_COLS } from '../../constants';
import { NavigationExtras, Router } from '@angular/router';
import { SubmissionService } from './submissions.service';
import ROUTES from '../../routes.const';

@Component({
  selector: 'app-submission',
  standalone: true,
  templateUrl: './submission.component.html',
  styleUrl: './submission.component.scss',
  imports: [SharedModule, SearchFieldComponent],
})
export class ActivitiesComponent {
  activitiesList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onHover: number = 0;
  ActivitiesCols: TableCols[] = ACTIVITIES_BUNDLE_COLS;
  BundleCols: TableCols[] = BUNDLE_COLS;

  constructor(private router: Router, private service: SubmissionService) {}

  async ngOnInit() {
    await this.getSubmissionBundle();
  }

  async getSubmissionBundle(): Promise<void> {
    try {
      const res = await this.service.fetchBundles();
      if (res) {
        this.activitiesList = res;
        this.isLoading = false;
      }
      return res;
    } catch (error: any) {
      this.isLoading = false;
    }
  }
  handleSearch(val: string): void {}

  handleHover = (index: number) => {
    this.onHover = index;
  };

  submissionDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([ROUTES.SUBMISSIONS_DETAILS], params);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'Audit':
        return 'tag-audit';
      case 'Completed':
        return 'tag-complete';
      case 'External Audit':
        return 'tag-external-audit';
      case 'Accepted':
        return 'tag-audit-complete';
      case 'SUBMITTED':
        return 'tag-submitted';
      default:
        return '';
    }
  }
}

export const ACTIVITIES_DATA = [
  {
    id: 1,
    name: 'Bundle 1',
    installer: 'Installer 1',
    date: new Date(),
    activities: [
      {
        id: 1,
        schemeName: 'Scheme 1',
        activityName: 'Activity 1',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'New',
      },
      {
        id: 2,
        schemeName: 'Scheme 2',
        activityName: 'Activity 2',
        typeOfCertificate: 'STC',
        certificateCount: 100,
        status: 'Accepted',
      },
    ],
    status: 'New',
  },
  {
    id: 2,
    name: 'Bundle 2',
    installer: 'Installer 2',
    date: new Date(),
    status: 'Accepted',
    activities: [
      {
        id: 1,
        schemeName: 'Scheme 1',
        activityName: 'Activity 1',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'New',
      },
      {
        id: 2,
        schemeName: 'Scheme 2',
        activityName: 'Activity 2',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'Accepted',
      },
    ],
  },
  {
    id: 3,
    name: 'Bundle 3',
    installer: 'Installer 3',
    date: new Date(),
    status: 'New',
    activities: [
      {
        id: 1,
        schemeName: 'Scheme 1',
        activityName: 'Activity 1',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'New',
      },
      {
        id: 2,
        schemeName: 'Scheme 2',
        activityName: 'Activity 2',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'Accepted',
      },
    ],
  },
  {
    id: 4,
    name: 'Bundle 4',
    installer: 'Installer 4',
    date: new Date(),
    status: 'Accepted',
    activities: [
      {
        id: 1,
        schemeName: 'Scheme 1',
        activityName: 'Activity 1',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'New',
      },
      {
        id: 2,
        schemeName: 'Scheme 2',
        activityName: 'Activity 2',
        typeOfCertificate: 'ESC',
        certificateCount: 100,
        status: 'Accepted',
      },
    ],
  },
];

export const AGENT_LIST = [
  { name: 'Agent 1' },
  { name: 'Agent 2' },
  { name: 'Agent 3' },
];

export const ASSESSMENT_LISt = [
  {
    id: 1,
    field: 'WH-New-Unit-Wide',
    type: 'Photo',
    status: 'reject',
    reject: { agent: 'Agent 1', reason: 'Photo not clear' },
  },
  { id: 2, field: 'Cust-NMI', type: 'DE-Numeric' },
  { id: 3, field: 'Cust-Meter', type: 'Boolean' },
  { id: 4, field: 'Meter', type: 'DE-Picklist' },
  {
    id: 5,
    field: 'Placeholder',
    type: 'DE-Placeholder',
    status: 'reject',
    reject: { agent: 'Agent 2', reason: 'Re Assessment - value not clear' },
  },
];

export const INSTALLATION_LIST = [
  {
    id: 1,
    field: '2000kW Capacity & Oxygen Trim',
    type: 'Boolean',
    status: 'reject',
    reject: { agent: 'Agent 3', reason: 'Wrong capacity and oxygen trim' },
  },
  { id: 2, field: 'Nameplate Capacity', type: 'DE-Numeric' },
  {
    id: 3,
    field: '200kw Capacity or higher',
    type: 'Boolean',
  },
  {
    id: 4,
    field: 'Compliance with Standards',
    type: 'Boolean',
  },
];

export const INSTALLER_DATA = [
  { name: 'Installer 1', email: 'arroragaur@gmail.com', status: 'Invited' },
  { name: 'Installer 2', email: 'jamesmullican@gmail.com', status: 'Active' },
  { name: 'Installer 3', email: 'robertbacins@gmail.com', status: 'Deleted' },
];

export const DASHBOARD = [
  {
    name: 'Eva Energy',
    new: 2,
    installation: 7,
    audit: 3,
    complete: 4,
    assessments: 2,
  },
  {
    name: 'Creditex',
    new: 5,
    installation: 3,
    audit: 7,
    complete: 5,
    assessments: 8,
  },
  {
    name: 'Installer 3',
    new: 4,
    installation: 5,
    audit: 8,
    complete: 9,
    assessments: 7,
  },
  {
    name: 'Demand MGR',
    new: 6,
    installation: 6,
    audit: 9,
    complete: 6,
    assessments: 5,
  },
];

export const DASHBOARD_JOB_DATA = [
  { name: 'Hot water', location: 'Lecture Hall A', status: 'New' },
  { name: 'HW New', location: 'Lecture Hall B', status: 'Audit' },
  { name: 'Lights', location: 'Lecture Hall G', status: 'Completed' },
];

export const AUDIT_DATA = [
  {
    id: 1,
    name: 'Activity 1',
    installer: 'Installer 1',
    date: new Date(),
    status: 'New',
  },
  {
    id: 2,
    name: 'Activity 2',
    installer: 'Installer 2',
    date: new Date(),
    status: 'Audit',
  },
  {
    id: 3,
    name: 'Activity 3',
    installer: 'Installer 3',
    date: new Date(),
    status: 'External Audit',
  },
  {
    id: 4,
    name: 'Activity 4',
    installer: 'Installer 4',
    date: new Date(),
    status: 'Audit Complete',
  },
  {
    id: 5,
    name: 'Activity 5',
    installer: 'Installer 5',
    date: new Date(),
    status: 'Submitted',
  },
  {
    id: 6,
    name: 'Activity 6',
    installer: 'Installer 6',
    date: new Date(),
    status: 'Completed',
  },
];

import { Component, OnInit } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { TabViewModule } from 'primeng/tabview';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NumberInputComponent } from '../../Components/number-input/number-input.component';
import { CalendarComponent } from '../../Components/calendar/calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { CertificateService } from './certificate.services';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Certificate, ColorMap, DropDownType } from '../../types';
import { CERTIFICATE_VALUE } from '../../constants';

@Component({
  selector: 'app-certificate-value',
  standalone: true,
  templateUrl: './certificate-value.component.html',
  styleUrl: './certificate-value.component.scss',
  providers: [MessageService],
  imports: [
    ChartModule,
    ButtonComponent,
    SharedModule,
    TabViewModule,
    NumberInputComponent,
    CalendarComponent,
    CalendarModule,
    DropdownComponent,
    InputSwitchModule,
  ],
})
export class CertificateValueComponent implements OnInit {
  data: any;
  options: any;
  display: boolean = false;
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  formData!: FormGroup;
  certificateData: any[] = [];

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: CertificateService,
    private messageService: MessageService
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required],
      price: [''],
      percentage: [''],
      flatPrice: new FormControl<boolean>(false),
    });
  }

  async ngOnInit() {
    this.certificateData = await this.getCertificatePrice();
    this.initializeChartOptions();
    this.updateChartData();
  }

  async getCertificatePrice(): Promise<any[]> {
    try {
      const data = await this.service.fetchCertificatePrice();
      return data;
    } catch (error: any) {
      return [];
    }
  }

  async onEnter(event: any): Promise<void> {
    try {
      const payload = {
        name: this.formData.value.name,
        percentage: event.value,
        date: moment(this.formData.value.date).format('YYYY-MM-DD'),
      };
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  updateChartData() {
    const colorMap: ColorMap = {
      STC: '--blue-500',
      VEEC: '--yellow-500',
      ESC: '--green-500',
      LGC: '--brown-500',
      ACCU: '--pink-100',
      PRC: '--pink-500',
    };

    const formattedLabel = this.getLastSixMonthsWithDates();

    const label = this.labelFormat();

    const data = this.groupByName(this.certificateData);

    const alignedData = this.alignDataWithLabels(data, formattedLabel);

    const datasets = Object.values(alignedData).map((item: any) =>
      this.createDataset(
        item.name,
        item.data,
        colorMap[item.name] || '--default-color'
      )
    );

    this.data = {
      labels: label,
      datasets: datasets,
    };
  }

  private getLastSixMonthsWithDates(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(currentMonth.clone().date(day).format('YYYY-MM-DD'));
      }
    }

    return result;
  }

  private labelFormat(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const monthLabel = currentMonth.format('MMMM');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(`${monthLabel}`);
      }
    }

    return result;
  }

  private groupByName(data: Certificate[]) {
    return data.reduce((acc, curr) => {
      if (!acc[curr.name]) {
        acc[curr.name] = {
          name: curr.name,
          data: [],
        };
      }
      acc[curr.name].data.push(curr);
      return acc;
    }, {} as { [key: string]: { name: string; data: Certificate[] } });
  }

  private alignDataWithLabels(
    data: { [key: string]: { name: string; data: any[] } },
    labels: string[]
  ): { [key: string]: { name: string; data: number[] } } {
    const labelPositions = labels.reduce((acc, label, index) => {
      if (moment(label, 'YYYY-MM-DD', true).isValid()) {
        acc[label] = index;
      }
      return acc;
    }, {} as { [key: string]: number });

    Object.values(data).forEach((item) => {
      const alignedData = Array(labels.length).fill(null);
      item.data.forEach((count, index) => {
        const date = moment(item.data[index].date).format('YYYY-MM-DD');
        if (date && labelPositions[date] !== undefined) {
          alignedData[labelPositions[date]] = count.finalPrice;
        }
      });
      item.data = alignedData;
    });

    return data;
  }

  initializeChartOptions() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context: { raw: any; dataset: { label: any } }) {
              const value = context.raw;
              return `${context.dataset.label}: $${value.toFixed(2)}`;
            },
          },
        },
        legend: {
          labels: { color: textColor },
        },
      },
      scales: {
        x: {
          ticks: {
            source: 'auto',
            maxTicksLimit: 6,
            color: textColorSecondary,
          },
          grid: { color: surfaceBorder, drawBorder: false },
        },
        y: {
          ticks: {
            color: textColorSecondary,
            callback: function (value: number) {
              return `$${value.toFixed(2)}`;
            },
          },
          grid: { color: surfaceBorder, drawBorder: false },
          beginAtZero: true,
        },
      },
    };
  }

  createDataset(label: string, data: number[], borderColor: string): any {
    const documentStyle = getComputedStyle(document.documentElement);
    return {
      label: label,
      data: data,
      fill: false,
      borderColor: documentStyle.getPropertyValue(borderColor),
      tension: 0.4,
      spanGaps: true,
    };
  }

  handleAddDialog(): void {
    this.display = true;
    this.formData.reset();
  }

  closeDialog(): void {
    this.display = false;
  }

  async onSave(): Promise<void> {
    const { name, price, date, flatPrice, percentage } = this.formData.value;
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const payload = {
      name,
      date: formattedDate,
      ...(flatPrice ? { percentage } : { price }),
    };

    try {
      const res = await this.service.onSavePrice(payload);
      if (res) {
        this.certificateData = await this.getCertificatePrice();
        this.updateChartData();
        this.display = false;
        this.successToast('Certificate Value Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }
}

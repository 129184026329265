import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private api: ApiService) {}

  async fetchProjectData(
    pageNo: number,
    pageSize: number,
    search?: string,
    payload?: string[] | null
  ): Promise<any> {
    try {
      const statusSearch = payload?.length !== 0 ? payload : null;
      const data = await firstValueFrom(
        this.api.post(
          `api/project/search?PageNumber=${
            pageNo + 1
          }&PageSize=${pageSize}&searchText=${search}`,
          statusSearch
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getByProjectId(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/project/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createProjectData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/project', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateProjectData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put('api/project', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteProjectData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/project/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchJobData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/jobs'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchJobDataById(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/jobs/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createJobData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post(`api/jobs`, payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteJobData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/jobs/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateJobData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put(`api/jobs`, payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getZoneName(postCode: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/jobs/${postCode}/zonename`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

<div class="header-containers">
  <div class="left-items">
    <img src="assets/Themis.logo.svg" alt="Themis Logo" />
    <span class="title">THEMIS</span>
  </div>
  <div class="right-items">
    <!-- <img src="assets/notification.icon.svg" /> -->
    <div class="profile-details" (click)="op.toggle($event)">
      <img src="assets/Arrow-Down.svg" />
      <h1>{{ userName }}</h1>
      <img
        [src]="profilePic || 'assets/Profile Icon 1.svg'"
        height="48px"
        width="48px"
        style="border-radius: 3rem"
      />
      <p-overlayPanel #op>
        <ng-template pTemplate="content">
          <div
            (mouseover)="handleHover(1)"
            (click)="handleProfileEdit()"
            class="list-item"
            [class.hover-bg-light-blue]="onHover === 1"
            (mouseleave)="onHover = 0"
          >
            <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
            <span style="font-size: 14px">Profile Settings</span>
          </div>
          <div
            (mouseover)="handleHover(2)"
            (click)="handleLogout()"
            class="list-item"
            [class.hover-bg-light-red]="onHover === 2"
            (mouseleave)="onHover = 0"
          >
            <i class="pi pi-sign-out" style="font-size: 1.3rem"></i>
            <span style="font-size: 14px">Log Out</span>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ACTIVITIES_COLS } from '../../constants';
import { TableCols } from '../../types';
import { AUDIT_DATA } from '../../mock-data';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-audit',
  standalone: true,
  imports: [SharedModule, SearchFieldComponent],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss',
})
export class AuditComponent {
  cols: TableCols[] = ACTIVITIES_COLS;
  activitiesList: any[] = AUDIT_DATA;
  isLoading: boolean = false;
  skeletonRows = new Array(10);
  onHover: number = 0;

  constructor(private router: Router) {}

  activitiesDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`audit/${rowData.id}`], params);
  }

  handleSearch(val: string): void {}

  handleHover = (index: number) => {
    this.onHover = index;
  };

  getStatusClass(status: string): string {
    switch (status) {
      case 'New':
        return 'tag-new';
      case 'Audit':
        return 'tag-audit';
      case 'Completed':
        return 'tag-complete';
      case 'External Audit':
        return 'tag-external-audit';
      case 'Audit Complete':
        return 'tag-audit-complete';
      case 'Submitted':
        return 'tag-submitted';
      default:
        return '';
    }
  }
}

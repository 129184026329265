import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class SubmissionService {
  constructor(private api: ApiService) {}

  async fetchBundles(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/bundle'));
      return data;
    } catch (error) {
      throw error;
    }
  }
}

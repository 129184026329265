import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { TabViewModule } from 'primeng/tabview';
import { InputComponent } from '../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '../../Components/button/button.component';
import { ApiService } from '../../Services/common-services.service';
import { MessageService } from 'primeng/api';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { DisabledInputComponent } from '../../Components/disabled-input/disabled-input.component';
import { TableCols } from '../../types';
import { ACTIVITIES_USER_COLS, USER_COLS } from '../../constants';
import { lastValueFrom } from 'rxjs';
import { UserProfileService } from './user-profile.service';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';

@Component({
  selector: 'app-profile-settings',
  standalone: true,
  providers: [MessageService, ApiService],
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss',
  imports: [
    SharedModule,
    TabViewModule,
    InputComponent,
    ButtonComponent,
    HttpClientJsonpModule,
    HttpClientModule,
    DisabledInputComponent,
  ],
})
export class ProfileSettingsComponent {
  profileImage: string | ArrayBuffer | null = null;
  companyImage: string | ArrayBuffer | null = null;
  profileData!: FormGroup;
  companyData!: FormGroup;
  filteredAddress: any[] = [];
  userProfileData: any;
  userData!: FormGroup;
  onSaveLoad: boolean = false;
  Boolean: any[] = [
    { name: 'Yes', key: 'yes' },
    { name: 'No', key: 'no' },
  ];
  onHover: number = 0;
  userCols: TableCols[] = USER_COLS;
  userList: any[] = [];
  editUser: boolean = false;
  allActivities: any[] = [];
  allActivitiesClone: any[] = [];
  rowDataUser: any;
  activitiesCols: TableCols[] = ACTIVITIES_USER_COLS;
  selectedRows: any[] = [];
  rowData: any;

  statusTag(status: string) {
    return status === 'Online' || status === 'ACTIVE'
      ? 'success'
      : status === 'INVITED'
      ? 'warning'
      : status === 'Offline'
      ? 'danger'
      : '';
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: UserProfileService,
    private api: ApiService,
    private messageService: MessageService
  ) {
    this.initializeProfileForms();
    this.initializeCompanyForms();
    this.userData = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async ngOnInit() {
    await this.getUserData();
    await this.getAllActivities();
    await this.getCompanyData();
    await this.getSelectedActivities();
  }

  handleHover(index: number): void {
    this.onHover = index;
  }

  async getUserData(): Promise<void> {
    try {
      const data = await this.service.fetchUserData();
      this.profileData.patchValue({
        name: data.name,
      });
      await this.getProfileImage();
      this.userProfileData = data;
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async getCompanyData(): Promise<void> {
    try {
      const data = await this.service.fetchAggregatorData();
      this.companyData.patchValue({
        ...data,
        area: data.area.freeformAddress,
        activities: data.activities.split(',').map(Number),
      });
      this.rowData = data;
      this.userList = data.users;
      await this.getCompanyLogo();
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  onSearchAddress(event: any): void {
    this.api.searchAddress(event.query).subscribe(
      (res: any) => {
        this.filteredAddress = res.results;
      },
      (error: any) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  async getSelectedActivities(): Promise<void> {
    try {
      const res = await this.service.fetchSelectedActivities();
      this.selectedRows = res;
    } catch (error: any) {}
  }

  private async getProfileImage(): Promise<any> {
    try {
      const response = await this.service.fetchUserProfilePic();
      const blob = new Blob([response], { type: 'image/jpeg' });
      this.profileImage = URL.createObjectURL(blob);
    } catch (error: any) {}
  }

  private async getCompanyLogo(): Promise<any> {
    try {
      const response = await this.service.fetchCompanyLogo();
      const blob = new Blob([response], { type: 'image/jpeg' });
      this.companyImage = URL.createObjectURL(blob);
    } catch (error: any) {}
  }

  async onProfileImage(event: any) {
    const file: File = event.target.files[0];
    this.profileData.patchValue({ image: file });
    await this.uploadProfileImage();
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profileImage = reader.result;
      };
    }
  }

  async uploadProfileImage() {
    try {
      const payload = {
        image: this.profileData.value.image,
      };
      const res = await this.service.updateUserProfileImage(payload);
      if (res) {
        window.location.reload();
      }
      return res;
    } catch (error: any) {
      return {};
    }
  }

  async onCompanyImage(event: any): Promise<void> {
    const file: File = event.target.files[0];
    this.companyData.patchValue({ image: file });
    await this.uploadCompanyLogo();
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.companyImage = reader.result;
      };
    }
  }

  async uploadCompanyLogo() {
    try {
      const payload = {
        logo: this.companyData.value.image,
      };
      const res = await this.service.updateCompanyLogo(payload);
      return res;
    } catch (error: any) {
      return {};
    }
  }

  async abnDetails(val: number) {
    const data = await lastValueFrom(this.api.getAbnData(val));
    return data;
  }

  async onEnter(event: any): Promise<void> {
    const data = await this.abnDetails(event.target.value);
    if (data.Message) {
      this.errorToast(data.Message);
    } else {
      this.companyData.patchValue({
        name: data.EntityName,
      });
    }
  }

  async onCompanySave(): Promise<void> {
    this.onSaveLoad = true;
    // const activities = this.selectedRows.map((c) => c.schemeActivityId);
    try {
      const payload = {
        ...this.companyData.value,
        status: 'Offline',
        area: this.companyData.value.area?.address
          ? this.companyData.value.area?.address
          : this.rowData?.area,
        activities: this.companyData.value.activities.join(','),
      };
      const data = await this.service.updateAggregatorData(payload);
      if (data) {
        await this.getSelectedActivities();
        this.onSaveLoad = false;
        this.successToast('Aggregator Details Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onProfileSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        name: this.profileData.value.name,
      };
      const data = await this.service.updateUserData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.successToast('User Data Updated Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.allActivities = this.allActivitiesClone.filter((item) =>
      item.schemeAndActivity.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  initializeProfileForms(): void {
    this.profileData = this.formBuilder.group({
      image: [''],
      name: ['', Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  initializeCompanyForms(): void {
    this.companyData = this.formBuilder.group({
      image: [''],
      name: ['', Validators.required],
      abn: ['', Validators.required],
      area: ['', Validators.required],
      contactName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactPhone: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
      activities: [[], Validators.required],
    });
  }

  onCancel(): void {
    window.history.back();
  }

  private async getAllActivities(): Promise<any[]> {
    try {
      const res = await this.service.fetchActivitiesData();
      this.allActivitiesClone = res;
      this.allActivities = res;
      return res;
    } catch (error: any) {
      return [];
    }
  }

  async onInviteUser(): Promise<void> {
    try {
      const payload = {
        ...this.userData.value,
        status: 'Invited',
        profileImagePath: '',
      };
      const data = await this.service.createUserData(payload);
      if (data) {
        this.successToast('User Invited Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  quoteUser(rowData: any): void {
    this.editUser = true;
    this.rowDataUser = rowData;
    this.userData.patchValue({
      name: rowData.name,
      email: rowData.email,
    });
  }

  async onUpdateUser(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        name: this.userData.value.name,
        email: this.userData.value.email,
        status: 'Invited',
      };
      const data = await this.service.updateUserData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.getCompanyData();
        this.successToast('User Data Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async addAccreditationNo(rowData: any): Promise<void> {
    try {
      const payload = {
        schemeId: rowData.schemeId,
        schemeActivityId: rowData.schemeActivityId,
        accreditationNumber: rowData.accreditationNumber,
      };
      const data = await this.service.postAccreditationData([payload]);
      if (data) {
        this.successToast('Accreditation Number Updated successfully');
      }
    } catch (error: any) {}
  }

  isRowEditable(rowData: any): boolean {
    return this.selectedRows.some(
      (selectedRow) => selectedRow.schemeActivityId === rowData.schemeActivityId
    );
  }
}

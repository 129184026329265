<div class="page-container activity-container">
  <div class="header-container">
    <app-search-text class="header" />
  </div>
  <div>
    <p-table
      [columns]="ActivitiesCols"
      [value]="activitiesBundleList"
      dataKey="id"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>

        <ng-container *ngIf="isLoading">
          <tr *ngFor="let _ of skeletonRows">
            <th class="skeleton" *ngFor="let col of columns">
              <p-skeleton></p-skeleton>
            </th>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-expanded="expanded"
      >
        <tr>
          <td>{{ rowData.schemeName }}</td>
          <td>{{ rowData.activityName }}</td>
          <td>{{ rowData.certificateName }}</td>
          <td>{{ rowData.certificateValue }}</td>
          <td style="width: 10%">
            <div [ngClass]="getStatusClass(rowData.status)">
              {{ rowData.status }}
            </div>
          </td>
          <td style="width: 5%" style="text-align: end">
            <div (click)="op.toggle($event)">
              <img src="assets/ActionMenu.svg" style="cursor: pointer" />
              <p-overlayPanel #op>
                <ng-template pTemplate="content">
                  <div
                    (mouseover)="handleHover(1)"
                    class="list-item"
                    [class.hover-bg-light-green]="onHover === 1"
                    (mouseleave)="onHover = 0"
                  >
                    <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                    <span style="font-size: 14px">Accept</span>
                  </div>
                  <div
                    (mouseover)="handleHover(2)"
                    class="list-item"
                    [class.hover-bg-light-red]="onHover === 2"
                    (mouseleave)="onHover = 0"
                  >
                    <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                    <span style="font-size: 14px">Reject</span>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="emptymessage"
        *ngIf="activitiesBundleList.length === 0"
      >
        <tr *ngIf="!isLoading">
          <td colspan="5">No Data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>


import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { InputComponent } from '../../Components/input/input.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { TableCols } from '../../types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INSTALLER_COLS } from '../../constants';
import { InstallerService } from './installer.service';
import { MessageService } from 'primeng/api';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-installers',
  standalone: true,
  templateUrl: './installers.component.html',
  styleUrl: './installers.component.scss',
  imports: [
    SharedModule,
    InputComponent,
    ButtonComponent,
    SearchFieldComponent,
  ],
  providers: [MessageService],
})
export class InstallersComponent {
  cols: TableCols[] = INSTALLER_COLS;
  installersList: any[] = [];
  display: boolean = false;
  formData!: FormGroup;
  onHover: number = 0;
  first = 0;
  pageNumber: number = 0;
  currentPageSize: number = 10;
  skeletonRows = new Array(this.currentPageSize);
  currentPricePageSize: number = 0;
  dataCount: number = 0;
  searchText: string | null = null;
  isLoading: boolean = true;
  rowData: any;
  onSaveLoad: boolean = false;
  searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder,
    private service: InstallerService,
    private messageService: MessageService
  ) {
    this.setupSearch();
    this.initializeForms();
  }

  async ngOnInit() {
    await this.getInstallerData(1, 10, '');
  }

  initializeForms(): void {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      userEmailAddress: ['', [Validators.required, Validators.email]],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  statusTag(status: string) {
    return status === 'Active'
      ? 'success'
      : status === 'INVITED'
      ? 'warning'
      : status === 'Deleted'
      ? 'danger'
      : '';
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value.trim();
      this.pageNumber = 1;
      this.first = 0;
      this.getInstallerData(
        this.pageNumber,
        this.currentPageSize,
        value.trim()
      );
    });
  }

  handleAddNew(): void {
    this.display = true;
  }

  closeDialog(): void {
    this.display = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.service.createInstallerData(this.formData.value);
      if (data) {
        await this.getInstallerData(1, 10, '');
        this.display = false;
        this.successToast('Installer Invited Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  handleEdit(rowData: any): void {
    this.display = true;
    this.rowData = rowData;
    this.formData.patchValue({
      ...rowData,
      userEmailAddress: rowData.users ? rowData.users[0].email : '',
    });
  }

  handleDelete(rowData: any): void {}

  async getInstallerData(
    pageNo: number,
    pageSize: number,
    search: string
  ): Promise<any[]> {
    try {
      const data = await this.service.fetchInstallerData(
        pageNo,
        pageSize,
        search
      );
      if (data) {
        this.installersList = data.data;
        this.dataCount = data.totalCount;
        this.isLoading = false;
      }
      return data;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchText) {
      this.getInstallerData(
        this.pageNumber,
        this.currentPageSize,
        this.searchText
      );
    } else {
      this.installersList = await this.getInstallerData(
        this.pageNumber,
        this.currentPageSize,
        ''
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.currentPageSize = event.value;
    if (this.searchText) {
      this.getInstallerData(this.pageNumber, event.value, this.searchText);
    } else {
      this.installersList = await this.getInstallerData(
        this.pageNumber,
        event.value,
        ''
      );
    }
  }
}

<div class="page-container audit-container">
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="activitiesList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="activity-name" (click)="activitiesDetails(rowData)">
          {{ rowData.name }}
        </td>
        <td>{{ rowData.installer }}</td>
        <td>{{ rowData.date | date : "yyyy-MM-dd" }}</td>
        <td style="width: 15%">
          <div [ngClass]="getStatusClass(rowData.status)">
            {{ rowData.status }}
          </div>
        </td>
        <td>
          <img
            *ngIf="
              rowData.status === 'External Audit' ||
              rowData.status === 'Submitted'
            "
            src="assets/DownloadIcon.svg"
            width="25px"
            height="25px"
          />
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content" [ngSwitch]="rowData.status">
              <div
                (mouseover)="handleHover(1)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 1"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'New'"
              >
                <i class="pi pi-play" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Start Audit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 2"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'New'"
              >
                <i class="pi pi-file-export" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Export for Audit</span>
              </div>
              <div
                (mouseover)="handleHover(3)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 3"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'Audit'"
              >
                <i class="pi pi-file-check" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Audit Complete</span>
              </div>
              <div
                (mouseover)="handleHover(4)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 4"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'Audit'"
              >
                <i class="pi pi-file-excel" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Audit Reject</span>
              </div>
              <div
                (mouseover)="handleHover(5)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 5"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'Audit Complete'"
              >
                <i class="pi pi-file-plus" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Certificate Request</span>
              </div>
              <div
                (mouseover)="handleHover(6)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 6"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'Submitted'"
              >
                <i class="pi pi-file-check" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Complete</span>
              </div>
              <div
                (mouseover)="handleHover(7)"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 7"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'External Audit'"
              >
                <i class="pi pi-file-check" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Audit Complete</span>
              </div>
              <div
                (mouseover)="handleHover(8)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 8"
                (mouseleave)="onHover = 0"
                *ngSwitchCase="'External Audit'"
              >
                <i class="pi pi-file-excel" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Audit Reject</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="activitiesList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="6">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

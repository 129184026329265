import { Component, Output, EventEmitter, Input } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { SharedModule } from '../../../shared.module';
import {
  SearchURL,
  SubscriptionKeyCredential,
  Aborter,
  MapsURL,
} from 'azure-maps-rest';
import { ButtonComponent } from '../../../Components/button/button.component';
import { InputComponent } from '../../../Components/input/input.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../Services/common-services.service';

@Component({
  selector: 'app-azure-map',
  standalone: true,
  templateUrl: './azure-map.component.html',
  styleUrl: './azure-map.component.scss',
  imports: [SharedModule, ButtonComponent, InputComponent],
})
export class AzureMapComponent {
  map!: atlas.Map;
  subscriptionKey: string = 'p52YZFE20GwoQ9D5pDfik-K6cy9lspUXh2j2MzZFuCE';
  searchClient: any;
  datasource!: atlas.source.DataSource;
  marker!: atlas.HtmlMarker;
  formData!: FormGroup;
  @Output() emitAddress = new EventEmitter<any>();
  coordinates!: string;
  @Input() jobDetails: any;
  @Input() projectData: any;

  constructor(private formBuilder: FormBuilder, private api: ApiService) {
    this.formData = this.formBuilder.group({
      searchQuery: [''],
    });
    const credential = new SubscriptionKeyCredential(this.subscriptionKey);
    const pipeline = MapsURL.newPipeline(credential);
    this.searchClient = new SearchURL(pipeline);
  }

  async ngOnInit(): Promise<void> {
    this.initializeMap();
    this.search();
  }

  private initializeMap(): void {
    let initialCenter: [number, number] = [133.7751, -25.2744];
    let initialZoom = 3;

    if (this.jobDetails.resolvedAddress && this.jobDetails.preciseLocation) {
      const coordinates = this.jobDetails.preciseLocation
        .split(',')
        .map(Number);
      if (
        coordinates.length === 2 &&
        !isNaN(coordinates[0]) &&
        !isNaN(coordinates[1])
      ) {
        initialCenter = [coordinates[1], coordinates[0]];
        initialZoom = 15;
      }
    }

    this.map = new atlas.Map('mapContainer', {
      center: initialCenter,
      zoom: initialZoom,
      language: 'en-US',
      showLogo: false,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: this.subscriptionKey,
      },
    });

    this.map.controls.add(
      [
        new atlas.control.PitchControl(),
        new atlas.control.CompassControl(),
        new atlas.control.StyleControl(),
        new atlas.control.ZoomControl(),
      ],
      {
        position: atlas.ControlPosition.TopRight,
      }
    );

    this.map.events.add('ready', () => {
      this.datasource = new atlas.source.DataSource();
      this.map.sources.add(this.datasource);
      this.map.layers.add(new atlas.layer.SymbolLayer(this.datasource));

      if (initialCenter[0] !== 133.7751 || initialCenter[1] !== -25.2744) {
        this.addMarker(initialCenter);
      }

      this.map.events.add('click', (e) => this.onMapClick(e.position));
    });
  }

  private onMapClick(position: atlas.data.Position | undefined): void {
    if (position) {
      this.addMarker(position);
      this.coordinates = `${position[1]}, ${position[0]}`;
      this.getAddressFromCoordinates(position[0], position[1]);
    }
  }

  private addMarker(position: atlas.data.Position): void {
    if (this.marker) {
      this.map.markers.remove(this.marker);
    }
    this.marker = new atlas.HtmlMarker({
      position: position,
      draggable: true,
    });
    this.datasource.clear();
    this.datasource.add(new atlas.data.Point(position));
    this.map.markers.add(this.marker);
  }

  private getAddressFromCoordinates(lat: number, lon: number): void {
    this.api.getMap(lon, lat).subscribe(
      (res: any) => {
        const address = {
          address: res.addresses[0].address,
          postalCode: res.addresses[0].address.postalCode,
          coordinates: this.coordinates,
        };
        this.emitAddress.emit(address);
      },
      (error: any) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  search(): void {
    let initialMarker;
    if (this.projectData.jobs.length === 0) {
      initialMarker = this.projectData.billingAddress.freeformAddress;
    } else {
      initialMarker = this.projectData.jobs[0].resolvedAddress.freeformAddress;
    }

    const searchText = this.formData.value.searchQuery || initialMarker;

    if (searchText.length > 3) {
      const aborter = Aborter.timeout(10000);
      this.searchClient
        .searchAddress(aborter, searchText)
        .then((response: any) => {
          const results = response.geojson.getFeatures().features;
          if (results.length > 0) {
            const position = results[0].geometry.coordinates;

            if (this.formData.value.searchQuery) {
              this.datasource.clear();
              this.datasource.add(new atlas.data.Point(position));
            }

            this.map.setCamera({
              center: position,
              zoom: 15,
            });
          }
        })
        .catch((error: any) => {
          console.error('Search error:', error);
        });
    }
  }
}

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class CertificateService {
  constructor(private api: ApiService) {}

  async fetchCertificatePrice(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get('api/aggregatorCertificate')
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async onSavePrice(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post('api/aggregatorCertificate', payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
